import React, { useContext, useEffect, useState } from 'react';
import Sticky from 'react-stickynode';
import classnames from 'classnames';
import { Tab as MuiTab, TabTypeMap as MuiTabType, Tabs as MuiTabs } from '@material-ui/core';

import { HEADER_DOM_ID } from 'settings/ui';

import { ResponsiveContext } from 'modules/App/Contexts/ResponsiveContext';

import styles from './Tabs.module.scss';

interface TabsType {
  children: (activeTab: string, changeTab: (newTab: string) => void) => React.ReactNode;
  className?: string;
  containerClassName?: string;
  defaultTab: string;
  headers: { id: string; label: MuiTabType['props']['label'] }[];
  id?: string;
  onTabChange?: (newTab: string) => void;
  sticky?: boolean;
  isMyProfile?: boolean;
}

export default function Tabs({
  children,
  className = undefined,
  containerClassName = undefined,
  defaultTab,
  headers,
  id = undefined,
  onTabChange,
  sticky = false,
  isMyProfile = false,
  ...tabsProps
}: TabsType) {
  const [activeTab, setActiveTab] = useState<string>(defaultTab?.toLowerCase());
  const { isResponsive } = useContext(ResponsiveContext);

  function changeTab(newTabId: string) {
    if (headers.some(tab => tab.id === newTabId)) {
      setActiveTab(newTabId.toLowerCase());
    }
  }

  const handleChange = (event, newTab: string) => {
    setActiveTab(newTab.toLowerCase());
    if (onTabChange) {
      onTabChange(newTab);
    }
  };

  useEffect(() => setActiveTab(defaultTab), [defaultTab]);

  const siteHeader = document.getElementById(HEADER_DOM_ID);

  return (
    <div className={classnames(className, styles.root)}>
      <Sticky enabled={sticky} top={siteHeader ? siteHeader.offsetHeight : 0} innerZ={12}>
        <div className={styles.tabs} id={id}>
          <MuiTabs
            centered={!isResponsive}
            classes={{
              flexContainer: containerClassName,
              indicator: styles.tabsIndicator,
              scrollable: styles.tabsScrollable,
            }}
            onChange={handleChange}
            scrollButtons="off"
            value={activeTab}
            variant={isResponsive ? 'scrollable' : 'standard'}
            {...tabsProps}
          >
            {headers.map(({ id, label }) => (
              <MuiTab
                classes={{
                  root: isMyProfile ? styles.tabRootProfile : styles.tabRoot,
                  selected: isMyProfile ? styles.tabSelectedProfile : styles.tabSelected,
                }}
                key={id}
                label={label}
                value={id}
              />
            ))}
          </MuiTabs>
        </div>
      </Sticky>

      {children(activeTab, changeTab)}
    </div>
  );
}
