import type { SearchType } from 'modules/HomePage/hooks/useSearch';

import {
  LABEL_CONTROLLED_PRICES_ONLY,
  LABEL_FREE_LOTS_ONLY,
  LABEL_HONO_BOOST,
  LABEL_NEWS,
  LABEL_PREVIEW,
  LABEL_REDUCED_VAT_2,
} from './labels';
import { RESPONSIVE_MEDIA_QUERY } from './ui';

export const EXTENDED_SEARCH_STEP = 0.1;
export const EXTENDED_SEARCH_MAX_LNG = 15;

export const LOCATION_TYPE_CITY = 'city';
export const LOCATION_TYPE_DEPARTMENT = 'department';
export const LOCATION_TYPE_REGION = 'region';

export const SORT_ORDER_ASC = 'ASC';
export const SORT_ORDER_DESC = 'DESC';
export const SORT_PROGRAM_BY_DELIVERY = 'delivery';
export const SORT_PROGRAM_BY_LOCALITY = 'locality';
export const SORT_PROGRAM_BY_POSITION = 'position';
export const SORT_PROGRAM_BY_SEARCH = 'search';
export const SORT_PROGRAM_BY_TAX = 'tax';

export const SORT_LOT_BY_TAX = 'tax';
export const SORT_LOT_BY_PROGRAM = 'program';
export const SORT_LOT_BY_EXPOSURE = 'exposure';
export const SORT_LOT_BY_NUMBER = 'number';
export const SORT_LOT_BY_KIND = 'kind';
export const SORT_LOT_BY_FLOOR = 'floor';
export const SORT_LOT_BY_SURFACE = 'surface';
export const SORT_LOT_BY_SURFACE_ANNEX = 'surfaceAnnex';
export const SORT_LOT_BY_PARKING = 'hasParking';
export const SORT_LOT_BY_PRICE_REDUCED_VAT = 'priceReducedVat';
export const SORT_LOT_BY_PRICE_INCLUDING_TAX = 'priceIncludingTax';
export const SORT_LOT_BY_PRICE_IMMO_HT = 'customPrice1';
export const SORT_LOT_BY_PRICE_MOB_HT = 'customPrice2';
export const SORT_LOT_BY_RENT = 'rent';
export const SORT_LOT_BY_RENT_CUSTOM = 'customRent';
export const SORT_LOT_BY_RENT_FURNISHED = 'rentFurnished';
export const SORT_LOT_BY_PROFITABILITY = 'profitability';
export const SORT_LOT_BY_DELIVERY = 'delivery';
export const SORT_LOT_BY_STATUS = 'status';
export const SORT_LOT_BY_TYPE = 'type';

export const SEARCH_CRITERIA_CONTROLLED_PRICE = 'prix_maitrises';
export const SEARCH_CRITERIA_FREE_LOTS = 'libre_uniquement';
export const SEARCH_CRITERIA_FURNISHED = 'pack_meuble';
export const SEARCH_CRITERIA_HONOBOOST = 'honoboost';
export const SEARCH_CRITERIA_NEW = 'nouveaute';
export const SEARCH_CRITERIA_PREVIEW = 'avant_premiere';
export const SEARCH_CRITERIA_REDUCED_VAT = 'tva_reduite';

export const SLIDER_RANGE = {
  increment: {
    budget: 10000,
    profitability: 1,
    surface: 1,
  },
  values: {
    budget: { min: 0, max: parseInt(window.vinci.REACT_APP_VINCI_MAX_BUDGET, 10) },
    profitability: { min: 0, max: 6 },
    surface: { min: 0, max: parseInt(window.vinci.REACT_APP_VINCI_MAX_SURFACE, 10) },
    budgetRange: {},
  },
};

export const KINDS_ORDER = ['T1', 'T2', 'T3', 'T4', 'T5', 'M1', 'M2', 'M3', 'M4', 'M5', 'Parking'];

export const OTHER_OPTIONS = [
  {
    value: SEARCH_CRITERIA_REDUCED_VAT,
    label: LABEL_REDUCED_VAT_2,
  },
  {
    value: SEARCH_CRITERIA_HONOBOOST,
    label: LABEL_HONO_BOOST,
  },
  {
    value: SEARCH_CRITERIA_NEW,
    label: LABEL_NEWS,
  },
  {
    value: SEARCH_CRITERIA_PREVIEW,
    label: LABEL_PREVIEW,
  },
  {
    value: SEARCH_CRITERIA_FREE_LOTS,
    label: LABEL_FREE_LOTS_ONLY,
  },
  {
    value: SEARCH_CRITERIA_CONTROLLED_PRICE,
    label: LABEL_CONTROLLED_PRICES_ONLY,
  },
];

export const SESSION_KEY_MAIN_VALUES = 'main_search_values';
export const MAP_DEFAULT_LAT = 47.227638;
export const MAP_DEFAULT_LNG = 2.11374;
export const MAP_DEFAULT_ZOOM = matchMedia(RESPONSIVE_MEDIA_QUERY).matches ? 5 : 6;
export const MAIN_SEARCH_DEFAULT_PARAMS = {
  mainParams: SLIDER_RANGE.values,
  mapParams: {
    lat: MAP_DEFAULT_LAT,
    lng: MAP_DEFAULT_LNG,
    zoom: MAP_DEFAULT_ZOOM,
  },
};

export const PROGRAM_DEFAULT_SORT_BY = SORT_PROGRAM_BY_POSITION;
export const PROGRAM_DEFAULT_SORT_ORDER = SORT_ORDER_ASC;

export const LOTS_DEFAULT_SORT_BY = SORT_PROGRAM_BY_POSITION;
export const LOTS_DEFAULT_SORT_ORDER = SORT_ORDER_ASC;

export const SESSION_KEY_MAP_STATE = 'mapState';

export const SEARCH_PANEL_DELTA = 40;
export const SEARCH_PANEL_HEIGHT_MIN = 156;
export const SEARCH_PANEL_MEDIUM_RATIO = 0.64;

export const SEARCH_PANEL_SIZE_FULL = 'Full';
export const SEARCH_PANEL_SIZE_MEDIUM = 'Medium';
export const SEARCH_PANEL_SIZE_MIN = 'Min';
export const SEARCH_PANEL_SIZE_NULL = 'Null';

export const SEARCH_TMS_INFOS = {
  lots: {
    pageTemplate: 'recherche',
    pageTemplateRef: 'resultats_lots',
  },
  programmes: {
    pageTemplate: 'recherche',
    pageTemplateRef: 'resultats_programmes',
  },
};

export const SEARCH_PANEL_TMS_INFOS = {
  navigation_pagename: 'mesrecherches',
  navigation_template: 'mesrecherches',
};

export const SEARCH_MAX_PRIZE = 500000;

export const SEARCH_NB_ROWS_API_LOAD = 100;

export const SEARCH_PREVIEW_FILTER_BLACKLIST: (keyof SearchType)[] = [
  'annexes',
  'budgetMin',
  'budgetMax',
  'kinds',
  'profitabilityMin',
  'profitabilityMax',
  'reducedVAT',
  'surfaceMin',
  'surfaceMax',
];
export const SEARCH_PREVIEW_OTHERS_BLACKLIST = [
  SEARCH_CRITERIA_CONTROLLED_PRICE,
  SEARCH_CRITERIA_FREE_LOTS,
  SEARCH_CRITERIA_HONOBOOST,
  SEARCH_CRITERIA_NEW,
  SEARCH_CRITERIA_REDUCED_VAT,
  SEARCH_CRITERIA_FURNISHED,
];
