import React from 'react';
import classnames from 'classnames';

import { LABEL_CONTACT, LABEL_DEFAULT_JOB } from 'settings/labels';

import Button from 'commonUi/Button/Button';

import styles from './ContactCard.module.scss';

interface ContactCardProps {
  className?: string;
  email?: string;
  firstname?: string;
  job?: string;
  lastname?: string;
  phone?: string;
  isMyProfile?: boolean;
}

export default function ContactCard({
  className,
  email,
  firstname,
  job = LABEL_DEFAULT_JOB,
  lastname,
  phone,
  isMyProfile = false,
}: ContactCardProps) {
  if (!lastname && !firstname) {
    return null;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.contact}>
        <div
          className={classnames(styles.contactName, { [styles.contactNameProfile]: isMyProfile })}
        >
          {lastname} {firstname}
        </div>
        <div className={styles.contactInfos}>
          <div className={styles.contactJob}>{job}</div>
          {email && (
            <>
              <span className={styles.contactSeparator}>-</span>
              <a
                className={classnames(styles.contactMail, {
                  [styles.contactMailProfile]: isMyProfile,
                })}
                href={`mailto:${email}`}
              >
                {email}
              </a>
            </>
          )}
        </div>
      </div>
      <div className={styles.contactButtons}>
        <div className={styles.buttonWrapper}>
          {email && (
            <Button
              className={classnames(styles.button, {
                [styles.buttonProfile]: isMyProfile,
              })}
              color="secondary"
              href={`mailto:${email}`}
              icon="mail"
              variant="contained"
            >
              {LABEL_CONTACT}
            </Button>
          )}
        </div>
        <div className={styles.buttonWrapper}>
          {phone && (
            <Button
              className={classnames(styles.button, {
                [styles.buttonTelProfile]: isMyProfile,
              })}
              color="primary"
              href={`tel:${phone}`}
              icon="call"
              variant="contained"
            >
              {phone}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
