import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import type { ComponentProps } from 'react';
import classnames from 'classnames';

import {
  LABEL_SEARCH_NO_RESULTS,
  LABEL_SEARCH_NO_RESULTS_MOBILE,
  LABEL_SEARCH_NO_RESULTS_NEAR_PROGRAMS,
} from 'settings/labels';

import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';

import { ResponsiveContext } from 'modules/App/Contexts/ResponsiveContext';

import { programIsPreview } from 'services/programs';

import { useBooklet } from 'hooks/useBooklet';
import { useModal } from 'hooks/useModal';
import { useSearch } from 'modules/HomePage/hooks/useSearch';

import { CustomScrollbar } from 'commonUi/CustomScrollbar/CustomScrollbar';
import SpinLoader from 'commonUi/SpinLoader/SpinLoader';
import ModalSendBooklet from 'modules/ActionsProgramsAndLots/ModalSendBooklet/ModalSendBooklet';

import ProgramResult from '../ProgramCard/ProgramResult';

import styles from './ProgramsList.module.scss';

const MODAL_TMS_VALUES = {
  open: (programRef: string) => ({
    navigation_pagename: 'resultats_programmes.alerteav',
    navigation_template: `recherche.${programRef}`,
  }),
  confirmed: (programRef: string) => ({
    navigation_pagename: 'resultats_programmes.alerteav.confirmation',
    navigation_template: `recherche.${programRef}`,
  }),
};

interface ProgramsListType {
  availableLots: Record<string, number>;
  isLoading?: boolean;
  isPanelExpanded?: boolean;
  onProgramMouseEnter?: ComponentProps<typeof ProgramResult>['onMouseEnter'];
  onProgramMouseLeave?: ComponentProps<typeof ProgramResult>['onMouseLeave'];
  pageTemplate?: string;
  pageTemplateRef?: string;
  programs: ProgramListType[];
  reduceMobilePanel?: () => void;
  setIsPanelExpanded?: (isPanelExpanded: boolean) => void;
}

export default function ProgramsList({
  availableLots,
  isLoading = false,
  isPanelExpanded,
  onProgramMouseEnter,
  onProgramMouseLeave,
  pageTemplate,
  pageTemplateRef,
  programs,
  reduceMobilePanel,
  setIsPanelExpanded,
}: ProgramsListType) {
  const [bookletProgram, setBookletProgram] = useState<ProgramListType>();
  const { isResponsive } = useContext(ResponsiveContext);
  const { locations, programRef, origin, nearProgram } = useSearch();
  const { open, openModal, closeModal } = useModal();
  const { booklet } = useBooklet(bookletProgram?.ref, open);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<any>();

  useEffect(() => {
    if (
      typeof programRef === 'string' &&
      origin === 'map' &&
      scrollRef.current?.osInstance &&
      document.querySelector(`#program-${programRef}`)
    ) {
      scrollRef.current?.osInstance().scroll(document.querySelector(`#program-${programRef}`), 500);
    } else if (
      !programRef &&
      scrollRef.current?.osInstance &&
      origin === 'map' &&
      document.querySelector(`#program-${programs?.[0]?.ref}`)
    ) {
      scrollRef.current
        ?.osInstance()
        .scroll(document.querySelector(`#program-${programs?.[0]?.ref}`), 500);
    }
  }, [programRef]);

  function openModalSendBooklet(programRef: string) {
    setBookletProgram(programs.find(program => program.ref === programRef));
    openModal();
  }

  const displayAnnex = !!nearProgram && locations.length === 1;

  const innerHtml = isLoading ? (
    <div className={styles.loading}>
      <SpinLoader width={100} height={100} />
    </div>
  ) : (
    <>
      {(!programs?.length || displayAnnex) && (
        <div className={styles.noResults}>
          <span>{isResponsive ? LABEL_SEARCH_NO_RESULTS_MOBILE : LABEL_SEARCH_NO_RESULTS}</span>
          {displayAnnex && !!programs?.length && (
            <span>{LABEL_SEARCH_NO_RESULTS_NEAR_PROGRAMS}</span>
          )}
        </div>
      )}
      <div
        className={classnames(styles.root, { [styles.isPanelExpanded]: isPanelExpanded })}
        ref={wrapperRef}
      >
        {programs?.map(program => (
          <div data-test-id="programs-list-item" key={program.ref} id={`program-${program.ref}`}>
            <ProgramResult
              availableLots={program?.ref ? availableLots[program.ref] : 0}
              openModalSendBooklet={openModalSendBooklet}
              program={program}
              isSelected={programRef === program?.ref}
              reduceMobilePanel={reduceMobilePanel}
              setIsPanelExpanded={setIsPanelExpanded}
              showLotsListButton={!programIsPreview(program)}
              tmsInfos={{
                actions:
                  pageTemplate && pageTemplateRef
                    ? {
                        confirmation: {
                          navigation_template: pageTemplate,
                          navigation_pagename: `${pageTemplateRef}_ajoutdossier.confirmation`,
                        },
                        default: {
                          navigation_template: pageTemplate,
                          navigation_pagename: `${pageTemplateRef}_ajoutdossier`,
                        },
                      }
                    : undefined,
                launchAlert: MODAL_TMS_VALUES,
              }}
              onMouseEnter={onProgramMouseEnter}
              onMouseLeave={onProgramMouseLeave}
            />
          </div>
        ))}
      </div>
    </>
  );

  if (isResponsive) {
    return (
      <>
        {innerHtml}
        {bookletProgram && open && (
          <ModalSendBooklet
            booklet={booklet}
            closeCallBack={closeModal}
            open
            program={bookletProgram}
          />
        )}
      </>
    );
  }
  return (
    <>
      <CustomScrollbar ref={scrollRef} className={styles.scroll}>
        {innerHtml}
      </CustomScrollbar>
      {bookletProgram && open && (
        <ModalSendBooklet
          booklet={booklet}
          closeCallBack={closeModal}
          open
          program={bookletProgram}
        />
      )}
    </>
  );
}
